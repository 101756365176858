














































import { Component, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({ name: 'LandingFooter', components: { BaseIcon } })
export default class LandingFooter extends Vue {
  links = []
}
